<template>
	<div class="content-header has-bottom-divider has-padding-top-3 has-margin">
		<div class="field is-grouped is-flex-grow-1 is-align-items-end">
			<VField
				:label="$t('sb.scriptList.scriptTypes')"
				:isFloating="true"
				class="has-margin-right-1"
				:class="{ 'is-active': scriptTypeValue }"
			>
				<VSelect :options="scriptTypeOptions" v-model="scriptTypeValue" />
			</VField>

			<VLink
				v-if="canCreateScript"
				:text="$t('sb.scriptList.addScript')"
				icon="plus"
				:hasUnderline="true"
				@click.prevent.stop="createNewClicked"
				class="has-margin-right-3 has-margin-bottom-05"
			/>

			<VSearch v-model="search" />
		</div>
	</div>

	<div class="scrollable-content">
		<SortableDataTable
			:scrollable="false"
			:rowClickedFn="rowClicked"
			defaultSort="last_time_edited"
			sortDirection="DESC"
			:data="filteredData"
			:columns="columns"
			:emptyText="$t('sb.scriptList.table.noResults')"
		/>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { reactive, toRefs } from '@vue/reactivity';
import {
	getStoreAction,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { formatForTable } from '@modules/ScriptBuilder/components/script';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { useCreateNewOnKeydown } from '@assets/scripts/composables/useCreateNewOnKeydown';
import usePermission from '@assets/scripts/composables/usePermission';
import {
	GET_SCRIPTS,
	GET_SCRIPT_TYPES,
} from '@modules/ScriptBuilder/endpoints';

export default {
	name: 'ScriptList',
	data: function () {
		return {
			// default value for script type filter
			scriptTypeValue: this.$t('general.all'),
			search: '',
		};
	},
	async setup () {
		const store = useStore();
		const { t } = useI18n();

		const state = reactive({
			scripts: [],
			/**
			 * Boolean to indicate whether the current
			 * user can create scripts
			 */
			canCreateScript: usePermission('Upsert', 'ScriptBuilder'),
		});

		const createNewClicked = () => {
			// create new script
			store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'SB'),
				true
			);
		};
		if(state.canCreateScript) {
			useCreateNewOnKeydown(createNewClicked);
		}

		const loadScripts = async () => {
			// get all scripts
			state.scripts = await useApiAsync(GET_SCRIPTS);
		};

		// subscribe to store action
		const unsubscribeAction = store.subscribeAction({
			after: async ({ type }) => {
				// check action type
				if (type === getStoreAction('UPDATE_SCRIPT_LIST', 'SB')) {
					// reload script list if needed
					await loadScripts();
				}
			}
		});

		await loadScripts();

		// values for script type filter
		let scriptTypeOptions = [];

		const scriptTypes = await useApiAsync(GET_SCRIPT_TYPES);

		if (scriptTypes) {
			scriptTypeOptions = scriptTypes.map((type) => {
				return {
					value: type,
					text: type,
				};
			});
		}

		// add 'All' as first option
		scriptTypeOptions.unshift({
			value: t('general.all'),
			text: t('general.all'),
		});

		return {
			...toRefs(state),
			scriptTypeOptions,
			unsubscribeAction,
			createNewClicked,
		};
	},
	computed: {
		columns: function() {
			return [
				{
					label: this.$t('sb.scriptList.table.columns.name'),
					field: 'name',
					sortable: true,
					searchable: true,
					cellClass: 'has-text-weight-semibold',
					// width: '30%',
				},
				{
					label: this.$t('sb.scriptList.table.columns.script_type'),
					field: 'type',
					sortable: true,
					searchable: true,
					// width: '15%',
				},
                {
					label: this.$t('sb.scriptList.table.columns.host_name'),
					field: 'host_name',
					sortable: true,
					searchable: true,
					isDate: true,
					// width: '25%',
				},
				{
					label: this.$t('sb.scriptList.table.columns.last_time_edited'),
					field: 'last_time_edited',
					sortable: true,
					searchable: true,
					isDate: true,
					// width: '25%',
				},
                {
					label: this.$t('sb.scriptList.table.columns.status'),
					field: 'status',
					sortable: true,
					searchable: true,
					// width: '15%',
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						edit: {
							title: this.$t('general.edit'),
							isTool: true,
							icon: 'edit',
						},
						view: {
							title: this.$t('general.inspect'),
							isTool: true,
							icon: 'eye',
						}
					},
					click: this.rowClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				},
			];
		},
		tableFields: function () {
			return formatForTable(this.scripts);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// loop over all scripts
			const newData = this.tableFields.filter((row) => {
				// filter on Script type
				return  Helpers.filterByValue(
					row,
					'type',
					this.scriptTypeValue,
					this.$t('general.all')
				);
			});

			// return if no rows match
			if (this.search.length === 0 || newData.length === 0) return newData;

			// filter on search string
			return Helpers.filterByString(
				newData,
				this.search,
				this.searchableCols
			);
		},
	},
	methods: {
		rowClicked({ guid }) {
			// dispatch action to show clicked script
			this.$store.dispatch(
				getStoreAction('LOAD_AND_SHOW_SCRIPT', 'SB'),
				guid,
			);
		},
		deleteClicked({ guid }) {
			// ask confirmation to delete script
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('sb.scriptList.delete.confirm.title'),
				body: this.$t('sb.scriptList.delete.confirm.body'),
				confirmButtonText: this.$t(
					'sb.scriptList.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to delete clicked script
					this.$store.dispatch(
						getStoreAction('DELETE_SCRIPT', 'SB'),
						guid,
					);
				},
			});
		},
		sortByDate(a, b, isAsc) {
			return Helpers.date.sortByIsoDate(a.time, b.time, isAsc);
		},
	},
	unmounted: function () {
		// unsubscribe from actions
		this.unsubscribeAction();
	},
};
</script>
