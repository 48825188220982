<template>
	<div class="app-layer" :class="{ 'is-active': scriptActive }">
		<TheScriptDetails v-if="scriptActive" />
	</div>
	<div
		class="overlay-layer app-layer"
		:class="{ 'is-active': !scriptActive }"
	>
		<NewScript v-if="showCreateNew" />

		<Suspense>
			<TheScriptOverview v-show="showOverview" />
		</Suspense>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

import TheScriptDetails from '@modules/ScriptBuilder/materials/structures/TheScriptDetails.vue';
import TheScriptOverview from '@modules/ScriptBuilder/materials/TheScriptOverview.vue';
import NewScript from '@modules/ScriptBuilder/materials/structures/NewScript.vue';

export default {
	name: 'TheScriptBuilder',
	components: {
		TheScriptDetails,
		TheScriptOverview,
		NewScript,
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether the form to
			 * create a new script should be displayed
			 */
			showCreateNew: getStoreGetter('SHOW_CREATE_NEW', 'SB'),
			/**
			 * Boolean to indicate whether a script is
			 * currently active
			 */
			scriptActive: getStoreGetter('SCRIPT_ACTIVE', 'SB'),
		}),
		/**
		 * Boolean to indicate whether the current
		 * user can read (view) scripts
		 */
		canReadScript: function () {
			return usePermission('Read', 'ScriptBuilder');
		},
		showOverview: function () {
			return !this.showCreateNew && !this.scriptActive && this.canReadScript;
		}
	},
	unmounted: function () {
		// reset store to default state when page is unmounted
		this.$store.dispatch(getStoreAction('RESET', 'SB'));
	},
};
</script>
