<template>
	<VOverlay
		:title="$t('sb.newScript.title')"
		:size="2"
		:isVCentered="false"
		:closeOnBackgroundClick="true"
		:hasBackground="true"
		:modelValue="true"
		@update:modelValue="overlayClosed"
		:modalHasSharpCorner="true"
		@keydown.meta.s.prevent.stop="submit"
	>
		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('sb.newScript.whatName')" />

		<VInputString
			:isRequired="true"
			v-model:valueModel="scriptNameValue"
			:label="$t('sb.newScript.nameInputLabel')"
			:trim="true"
			:trimFunction="trimScriptName"
			@update:valueModel="markModified"
		/>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('sb.newScript.giveDescription')" />

		<VTextarea
			v-model="scriptDescription"
			:placeholder="$t('sb.newScript.giveDescription')"
			class="has-margin-bottom-4"
			:isNarrow="true"
		/>

		<template #footer>
			<VButton
				:text="$t('sb.newScript.submitButton')"
				icon="chevron-right"
				:iconOnRight="true"
				classes="button-modal-footer"
				:isDisabled="!isValid"
				@clicked="submit"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import { trimScriptName } from '@modules/ScriptBuilder/components/script.js';

export default {
	name: 'NewScript',
	data: function () {
		return {
			scriptNameValue: '',
			scriptDescription: '',
			trimScriptName,
		};
	},
	computed: {
		...mapGetters({
			modified: getStoreGetter('MODIFIED', 'SB'),
		}),
		isValid: function () {
			return this.scriptNameValue.trim();
		},
	},
	methods: {
		markModified: function () {
			// no need to trigger this mutation more than once
			if (this.modified) return;

			// mark script as modified whenever a
			// change is made
			this.$store.commit(
				getStoreMutation('MARK_MODIFIED', 'SB')
			);
		},
		overlayClosed: function () {
			this.$store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'SB'),
				false
			);
		},
		submit: function () {
			if (!this.isValid) return;

			this.$store.dispatch(getStoreAction('NEW_SCRIPT', 'SB'), {
				name: this.scriptNameValue.trim(),
				description: this.scriptDescription.trim(),
			});
		},
	},
};
</script>
