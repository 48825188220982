<template>
	<div class="app-options js-app-options">
		<div class="app-options-left">
			<VLink
				:text="backButtonText"
				icon="chevron-left"
				:href="null"
				@click.prevent.stop="backClicked"
			/>
			<div
				class="edit-name-wrapper"
				:class="{ 'is-editing': editingName }"
			>
				<div class="edit-name-label">
					{{ nameLabel }}
				</div>
				<div class="edit-name-row">
					<span
						:contenteditable="editingName"
						ref="nameInput"
						class="edit-name"
						@click.stop="startEditName"
						@keypress.enter.prevent="saveName"
						@blur="saveName"
						@keydown.esc="stopEditing"
					>
						{{ name }}
					</span>
					<VIcon
						name="edit"
						v-if="editMode"
						@click.stop="startEditName"
						class="edit-name-trigger"
					/>
				</div>
			</div>
		</div>
		<div v-if="showButtons" class="app-options-buttons">
			<VButton
				:icon="validationButtonIcon"
				:title="validationButtonText"
				:text="validationButtonText"
				:icon-on-right="true"
				:disabled="!canBeValidated"
				variant="is-quaternary"
				classes="app-options-button"
				:class="{ 'is-active': !errorsListCollapsed }"
				@clicked.prevent.stop="validateClicked"
			/>
			<VButton
				icon="chevron-right"
				:title="$t('appOptions.buttons.save')"
				:text="$t('appOptions.buttons.save')"
				:icon-on-right="true"
				:disabled="!canBeSaved"
				variant="is-quaternary"
				classes="app-options-button app-options-button-save"
				@clicked.prevent.stop="saveClicked"
			/>
			<VButton
				icon="chevron-right"
				:title="$t('appOptions.buttons.publish')"
				:text="$t('appOptions.buttons.publish')"
				:icon-on-right="true"
				:disabled="!canBePublished || hasErrors"
				variant="is-quaternary"
				classes="app-options-button app-options-button-publish"
				@clicked.prevent.stop="publishClicked"
			/>
			<Validation
				:editMode="canBeValidated"
				:refreshValidationFn="validateClickedFn"
				:errorListItemIsClickable="validationErrorListItemIsClickable"
			/>
		</div>
	</div>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import { mapGetters } from 'vuex';
import { ref } from 'vue';
import Field from '@assets/scripts/components/field';

export default {
	name: 'TheAppOptions',
	data: function () {
		return {
			editingName: false,
			publishAfterValidation: false,
		};
	},
	props: {
		editMode: {
			type: Boolean,
			default: false,
		},
		showButtons: {
			type: Boolean,
			default: true,
		},
		trimName: {
			type: Boolean,
			default: true,
		},
		backButtonText: {
			type: String,
			default: 'Back to overview',
		},
		backClickedFn: {
			type: Function,
		},
		publishClickedFn: {
			type: Function,
		},
		validateClickedFn: {
			type: Function,
		},
		saveClickedFn: {
			type: Function,
		},
		nameLabel: {
			type: String,
			default: 'Name',
		},
		canBeSaved: {
			type: Boolean,
			default: false,
		},
		canBePublished: {
			type: Boolean,
			default: false,
		},
		canBeValidated: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			default: '',
		},
		validationErrorListItemIsClickable: {
			type: Boolean,
			default: true,
		},
		trimFunction: {
			type: Function,
			default: Field.trimChildName,
		},
	},
	setup() {
		const nameInput = ref(null);
		return { nameInput };
	},
	watch: {
		isValid: function () {
			// publish after validating when isValid is true and publish button clicked and publish function exist
			if (
				this.isValid &&
				this.publishAfterValidation &&
				this.publishClickedFn
			) {
				this.publishClickedFn();
			} else {
				this.publishAfterValidation = false;
			}
		},
	},
	mounted: function() {
		document.addEventListener('keydown', this.keydownClicked)
	},
	unmounted: function() {
		document.removeEventListener('keydown', this.keydownClicked)
	},
	methods: {
		/**
		 * Returns true if Ctrl+s or cmd+s were pressed.
		 */
		isSavingCommand: function(event) { 
			if (Helpers.isCtrlClicked(event)) {
				return event.keyCode === 83;
			}
			return false;
		},
		/**
		 * Returns true if Ctrl+alt+p or cmd+alt+p were pressed.
		 */
		isPublishCommand: function(event) { 
			if (Helpers.isCtrlClicked(event) && Helpers.isAltClicked(event)) {
				return event.keyCode === 80;
			}
			return false;
		},
		/**
		 * Returns true if Ctrl+alt+v or cmd+alt+v were pressed.
		 */
		isValidateCommand: function(event) { 
			if (Helpers.isCtrlClicked(event) && Helpers.isAltClicked(event)) {
				return event.keyCode === 86;
			}
			return false;
		},
		/**
		 * Called after keydown
		 */
		keydownClicked: function (event) {
			if(this.globalSaveIsPossible) {
				// Called after click on the ctrl+s or cmd+s in keyboard
				if (this.isSavingCommand(event)) {
					event.preventDefault();
					if(this.canBeSaved) {
						this.saveClicked();
					}	
				}
				// Called after click on the Ctrl+alt+p or cmd+alt+p in keyboard
				else if (this.isPublishCommand(event)) {
					event.preventDefault();
					if(this.canBePublished || !this.hasErrors) {
						this.publishClicked();
					}
				}
				// Called after click on the Ctrl+alt+v or cmd+alt+v  in keyboard
				else if (this.isValidateCommand(event)) {
					event.preventDefault();
					if(this.canBeValidated) {
						if(this.validationMode){
							this.validateClickedFn();
						} else {
							this.validateClicked();
						}
					}
				}
			}
		},
		/**
		 * Called after click on the 'Save' button
		 */
		saveClicked: function () {
			if (this.saveClickedFn) {
				this.saveClickedFn();
			}
		},
		/**
		 * Called after click on the 'Publish' button
		 */
		publishClicked: function () {
			if (!this.isValid) {
				this.validateClicked();
				this.publishAfterValidation = true;
				return;
			}
			if (this.publishClickedFn) {
				this.publishClickedFn();
			}
		},
		/**
		 * Called after click on the 'Validate' button
		 */
		validateClicked: function () {
			this.toggleList();
			this.publishAfterValidation = false;
			if (!this.validationMode && this.validateClickedFn) {
				this.validateClickedFn();
			}
		},
		toggleList: function () {
			// update errors list state
			this.$store.commit(
				getStoreMutation('TOGGLE_ERRORS_LIST_COLLAPSED'),
				!this.errorsListCollapsed,
				{
					root: true,
				}
			);
		},
		/**
		 * Called after click on the 'Back to overview' button
		 */
		backClicked: function () {
			if (this.backClickedFn) {
				this.backClickedFn();
			}
		},
		/**
		 * Called when user starts editing the name
		 */
		startEditName: function () {
			if (!this.editMode) return;

			// set marker
			this.editingName = true;

			// set this in JS to prevent having to wait until
			// next tick to focus into editable region
			this.nameInput.setAttribute('contenteditable', true);

			// set focus in content editable element
			Helpers.focusContentEditable(this.nameInput);
		},
		/**
		 * Called to save user entered name
		 */
		saveName: function (ev) {
			if (!this.editingName) return;
			this.$emit(
				'update:name',
				this.trimName
					? this.trimFunction(ev.target.innerText)
					: ev.target.innerText
			);

			// stop editing the name
			this.stopEditing();
		},
		/**
		 * Called when users stops editing the name
		 */
		stopEditing: function () {
			// set content of contenteditable region to
			// current name
			this.nameInput.innerText = this.trimName
				? this.trimFunction(this.name)
				: this.name;

			// set marker
			this.editingName = false;
		},
	},
	computed: {
		...mapGetters({
			/**
			 * Gets erros array
			 */
			errors: getStoreGetter('VALIDATION_ERRORS'),
			/**
			 * Get errorsListCollapsed from the store
			 */
			errorsListCollapsed: getStoreGetter('ERRORS_LIST_COLLAPSED'),
			/**
			 * Get validation mode from the store
			 */
			validationMode: getStoreGetter('VALIDATION_MODE'),
			/**
			 * Get valid state
			 */
			isValid: getStoreGetter('IS_VALID'),
			/**
			 * Get validation in progress state
			 */
			validationInprogress: getStoreGetter('VALIDATION_IN_PROGRESS'),
			/**
			 * check if a global save is possible
			 */
			globalSaveIsPossible: getStoreGetter('GLOBAL_SAVE_POSSIBLE'),
		}),
		errorsCount: function () {
			return this.errors.length;
		},
		hasErrors: function () {
			return this.errorsCount > 0;
		},
		/**
		 * Get icon name for validation button
		 */
		validationButtonIcon: function () {
			if (!this.validationMode) {
				return 'chevron-right';
			} else if (this.errorsListCollapsed) {
				return 'chevron-down';
			} else {
				return 'chevron-up';
			}
		},
		/**
		 * Get title and text for validation button
		 */
		validationButtonText: function () {
			if (this.validationInprogress) {
				return this.$t('appOptions.buttons.validationInprogress');
			} else if (!this.validationMode) {
				return this.$t('appOptions.buttons.validate');
			} else if (this.isValid) {
				return this.$t('appOptions.buttons.validated');
			} else if (this.hasErrors) {
				return this.$t('appOptions.buttons.validationToggleWithCount', {
					count: this.errorsCount,
				});
			} else {
				// if no errors but is not valid should return this
				return this.$t('appOptions.buttons.requiresValidation');
			}
		},
	},
};
</script>
