<template>
	<div class="page-with-options-wrapper">
		<TheAppOptions
			:backButtonText="$t('sb.scriptDetails.backToOverview')"
			:backClickedFn="closeScript"
			:nameLabel="$t('sb.scriptDetails.scriptName')"
			v-model:name="scriptName"
			@update:name="updateName"
			:saveClickedFn="saveScript"
			:editMode="script.is_new"
			:canBeSaved="canBeSaved && !script.is_published"
			:showButtons="showButtons"
			:canBePublished="canBePublished"
			:publishClickedFn="publishScript"
			:canBeValidated="canBeValidated"
			:validateClickedFn="validateScript"
			:validationErrorListItemIsClickable="false"
			:trimName="true"
			:trimFunction="trimScriptName"
		/>

		<div class="page-content js-page-content script-page">
			<ContentBlock
				:size="3"
				:title="$t('sb.scriptDetails.scriptSettings')"
				:hasBorderUnderTitle="true"
			>
                <VInputString
                    v-model:valueModel="script.host_name"
                    :label="$t('sb.scriptDetails.hostName')"
                    @update:valueModel="markScriptAsUpdated"
					fieldClass="is-wide"
                />
                <VInputString
                    v-model:valueModel="script.type"
                    :label="$t('sb.scriptDetails.type')"
                    @update:valueModel="markScriptAsUpdated"
					fieldClass="is-wide"
                />
                <VField
                    :label="$t('sb.scriptDetails.scriptType')"
                    :isFloating="true"
                    :class="{ 'is-active': script.script_type }"
					class="is-wide"
                >
                    <VSelect :options="scriptTypeOptions" v-model="script.script_type" />
                </VField>
				<div class="has-padding-top-1">
					<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('sb.scriptDetails.description')" />

					<VTextarea
						v-model="script.description"
						:placeholder="$t('sb.scriptDetails.description')"
						class="has-margin-bottom-4"
						:isNarrow="true"
						@change="markScriptAsUpdated"
						:disabled="!canBeEdited"
					/>
				</div>
			</ContentBlock>
			<ContentBlock
				:size="3"
				:title="$t('sb.scriptDetails.commit')"
				:hasBorderUnderTitle="true"
			>
			    <VInputString
                    v-model:valueModel="script.commit"
                    :label="$t('sb.scriptDetails.commit')"
                    @update:valueModel="markScriptAsUpdated"
					:isRequired="true"
					fieldClass="is-wide"
                />
			</ContentBlock>
            <ContentBlock
				:size="3"
				:title="$t('sb.scriptDetails.scriptCode')"
				:hasBorderUnderTitle="true"
			>
                <MonacoEditor
                    theme="vs"
                    language="csharp"
                    :width="'100%'"
                    :height="800"
                    v-model:value="script.script"
                    @change="markScriptAsUpdated"
                ></MonacoEditor>
			</ContentBlock>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	getStoreGetter,
	getStoreMutation,
	getStoreAction,
} from '@assets/scripts/store/config';

import TheAppOptions from '@materials/structures/TheAppOptions.vue';
import MonacoEditor from '@materials/components/MonacoEditor.vue';
import { trimScriptName } from '@modules/ScriptBuilder/components/script.js';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_SCRIPT_TYPES } from '@modules/ScriptBuilder/endpoints';

export default {
	name: 'TheScriptDetails',
	components: {
		TheAppOptions,
		MonacoEditor,
	},
	data: function () {
		return {
			scriptName: '',
            scriptTypeOptions: [],
			trimScriptName,
		};
	},
	mounted: async function () {
		this.scriptName =
			this.script && this.script.name
				? this.script.name
				: '';

		// clear validation when script is loded
		this.$store.dispatch(getStoreAction('CLEAR_VALIDATION'));

		const scriptTypes = await useApiAsync(GET_SCRIPT_TYPES);

		if (scriptTypes) {
			this.scriptTypeOptions = scriptTypes.map((type) => {
				return {
					value: type,
					text: type,
				};
			});
		}
	},
	created: function () {
		// empty commit when script is publioshed to force the user to write new commit
		if (this.script.is_published) {
			this.script.commit = '';
		}
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether user modified the script
			 */
			modified: getStoreGetter('MODIFIED', 'SB'),
			/**
			 * Gets currently active Script
			 */
			script: getStoreGetter('CURRENT_SCRIPT', 'SB'),
			/**
			 * Boolean to indicate whether current script
			 * can be edited
			 */
			canBeEdited: getStoreGetter('CAN_BE_EDITED', 'SB'),
			/**
			 * Boolean to indicate whether current script
			 * can be saved
			 */
			canBeSaved: getStoreGetter('CAN_BE_SAVED', 'SB'),
			/**
			 * Boolean to indicate whether current script
			 * can be published
			 */
			canBePublished: getStoreGetter('CAN_BE_PUBLISHED', 'SB'),
		}),
		showButtons: function () {
			return this.canBeEdited;
		},
		canBeValidated: function () {
			return this.canBeEdited || this.canBePublished;
		},
	},
	methods: {
		markScriptAsUpdated: function () {
			// mark script as modified whenever a
			// change is made
			this.$store.commit(getStoreMutation('MARK_MODIFIED', 'SB'));
		},
		closeScript: function () {
			const closeOverlay = () => {
				// dispatch action to unset script
				this.$store.dispatch(getStoreAction('UNSET_SCRIPT', 'SB'));
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeOverlay();
			} else {
				// ask confirmation before closing if a change has been made
				// to the Script
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('sb.scriptDetails.close.confirm.title'),
					body: this.$t('sb.scriptDetails.close.confirm.body'),
					confirmButtonText: this.$t(
						'sb.scriptDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeOverlay();
					},
				});
			}
		},
		validateScript: function () {
			// dispatch action to Validate script
			this.$store.dispatch(
				getStoreAction('VALIDATE_CURRENT_SCRIPT', 'SB')
			);
		},
		saveScript: function () {
			this.$store.dispatch(
				getStoreAction('SAVE_CURRENT_SCRIPT', 'SB')
			);
		},
		publishScript: function () {
			// ask confirmation before publishing
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('sb.scriptDetails.publish.confirm.title'),
				body: this.$t('sb.scriptDetails.publish.confirm.body'),
				confirmButtonText: this.$t(
					'sb.scriptDetails.publish.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to publish meta script
					this.$store.dispatch(
						getStoreAction('PUBLISH_CURRENT_SCRIPT', 'SB')
					);
				},
			});
		},
		updateName: function (newName) {
			// commit mutation in store
			this.$store.commit(getStoreMutation('SET_NAME', 'SB'), newName);
		},
	},
};
</script>
