<template>
  <div class="monaco-editor has-padding-top-1" :style="style"></div>
</template>

<script>
// https://github.com/bazingaedward/monaco-editor-vue3 more information about this library
// https://microsoft.github.io/monaco-editor/index.html 
import { computed, toRefs } from 'vue'
import loader from '@monaco-editor/loader';

export default {
  name: 'MonacoEditor',
  props: {
    width: { type: [String, Number], default: '100%' },
    height: { type: [String, Number], default: '100%' },
    value: String,
    language: { type: String, default: 'csharp' },
    theme: { type: String, default: 'vs' },
    options: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  emits: ['editorWillMount', 'editorDidMount', 'change', 'update:value'],
  setup(props) {
    const { width, height } = toRefs(props)
    const style = computed(() => {
      const fixedWidth = width.value.toString().includes('%')
        ? width.value
        : `${width.value}px`
      const fixedHeight = height.value.toString().includes('%')
        ? height.value
        : `${height.value}px`
      return {
        width: fixedWidth,
        height: fixedHeight,
        'text-align': 'left',
      }
    })
    return {
      style,
    }
  },
  mounted() {
    loader.init().then((monaco) => {
     this.initMonaco(monaco)
   });
  },
  beforeDestroy() {
    this.editor && this.editor.dispose()
  },
  methods: {
    initMonaco(monaco) {
      this.$emit('editorWillMount', monaco)
      const { value, language, theme, options } = this
      this.editor = monaco.editor[
        'create'
      ](this.$el, {
        value: value,
        language: language,
        theme: theme,
        ...options,
      })
      // @event `change`
      const editor = this._getEditor()
      editor &&
        editor.onDidChangeModelContent((event) => {
          const value = editor.getValue()
          if (this.value !== value) {
            this.$emit('change', value, event)
            this.$emit('update:value', value)
          }
        })
      this.$emit('editorDidMount', this.editor)
    },
    _setValue(value) {
      let editor = this._getEditor()
      if (editor) return editor.setValue(value)
    },
    _getValue() {
      let editor = this._getEditor()
      if (!editor) return ''
      return editor.getValue()
    },
    _getEditor() {
      if (!this.editor) return null
      return this.editor
    },
  },
  watch: {
    options: {
      deep: true,
      handler(options) {
        this.editor.updateOptions(options)
      },
    },
    value() {
      this.value !== this._getValue() && this._setValue(this.value)
    },
  },
};
</script>