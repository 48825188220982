import { onMounted, onUnmounted } from 'vue';
import Helpers from '@assets/scripts/helpers';

export const useCreateNewOnKeydown= (createNewFn) => {

    const keydownClicked = (event) => {
        if (Helpers.isCreateNewCommand(event)) {
            event.preventDefault();
            createNewFn();
        }
    };

    onMounted(() => document.addEventListener('keydown', keydownClicked));
    onUnmounted(() => document.removeEventListener('keydown', keydownClicked));
}